import {
  GET_ALL_METADATA,
  GET_METADATA_SUCCESS ,
  GET_METADATA_ERROR ,
  SET_META_STATUS,
  METADATA_WITH_FILTER ,
  METADATA_WITH_ORDER ,
  METADATA_SEARCH ,
  METADATA_ADD_ITEM ,
  METADATA_ADD_ITEM_SUCCESS ,
  METADATA_ADD_ITEM_ERROR ,
  METADATA_SELECTED_ITEMS_CHANGE,
  UPDATE_ALL_METADATA
} from '../constants';
import produce from 'immer';
import { DateTime } from 'utils/universe';
import { PRE_LINK } from 'env';


export const categories = ['Quiz','Poll','Market research', 'Academic research', 'Employee feedback','Customer feedback','Other feedback','Lead generation','Registration','Other'];

const INIT_STATE = {
  metaStatus:null,
  allMetaData: null, // to store all the metadata as objects
  allMetaDataArray:[], // to store all the metadata as array
  chunkMetaDataArray:[], // to store all the metadata as array for working [ lot of updates will be made here ]
  metaOverView:{
    allSurveys:0,
    recentSurvey:0,
    ongoingSurvey:0,
    completedSurvey:0
  },
  error: '',
  filter: null,
  searchKeyword: '',
  orderColumn: null,
  loading: false,
  labels: [],
  orderColumns: [
    { column: 'created_at', label: 'Created date' },
    { column: 'category', label: 'Category' },
    { column: 'status', label: 'Status' },
  
  ],
  categories:categories,
  new:null,
  selectedItems: [],
};

function Moc(val,prev){ // Meta overview calculation
  var mo = {
    allSurveys:prev.allSurveys,
    recentSurvey:prev.recentSurvey,
    ongoingSurvey:prev.ongoingSurvey,
    completedSurvey:prev.completedSurvey
  };
  for(var attr in val){
    if(attr == 'created_at' && DateTime.isWeek(val[attr])){
      mo.recentSurvey += 1;
    }
    else if(attr == 'status'){
      mo.ongoingSurvey = (val[attr] == 'Active') ? mo.ongoingSurvey+1 : mo.ongoingSurvey; 
      mo.completedSurvey = (val[attr] == 'Completed') ? mo.completedSurvey+1 : mo.completedSurvey; 
    }
  }
  mo.allSurveys ++;
  return mo;
}

function Mc(aM){ // Meta calculation
  var Meta = {
    overview:{
      allSurveys:0,
      recentSurvey:0,
      ongoingSurvey:0,
      completedSurvey:0
    },
    array:[]
  }

  for (var i  in aM){
    var newValue = Object.assign({},aM[i]);
    Meta.overview = Moc(aM[i],Meta.overview);
    newValue['created_at'] = DateTime.formatUTC(newValue['created_at']);
    Meta.array.push(newValue);
  }
  return Meta;
}

export default (state = INIT_STATE, action) => {
  return produce(state,draft=>{
    switch (action.type) {
      
      case GET_ALL_METADATA:
        draft.loading = false;
        break;
  
      case GET_METADATA_SUCCESS:
        draft.loading = true;
        draft.metaStatus  = 200;
        draft.allMetaData = action.payload;

        // Format & Convert `allMetaData` object to array
        var McRes = Mc(action.payload);
        draft.allMetaDataArray   = McRes.array;
        draft.chunkMetaDataArray = McRes.array;
        draft.metaOverView       = McRes.overview;
        break;
  
      case GET_METADATA_ERROR:
        draft.loading = true;
        draft.metaStatus    = action.payload;
        break;
      
      case SET_META_STATUS:
        draft.loading = false;
        draft.metaStatus = action.payload
        break;

      case METADATA_WITH_FILTER:
        if (action.payload.column === '' || action.payload.value === '') {
          draft.loading = true;
          draft.chunkMetaDataArray   = draft.allMetaDataArray;
          draft.filter   = null;
          break;
        }

        const filteredItems = draft.allMetaDataArray.filter((item) => item[action.payload.column] === action.payload.value);
        draft.loading = true;
        draft.chunkMetaDataArray   =  filteredItems;
        draft.filter   =  {
          column: action.payload.column,
          value: action.payload.value,
        };
        break;
  
      case METADATA_WITH_ORDER:
        if (action.payload === '') {
          draft.loading = true;
          draft.chunkMetaDataArray   = draft.chunkMetaDataArray ;
          draft.orderColumn    = null;
          break;
        }

        var sortedItems = draft.chunkMetaDataArray.sort((a, b) => {
          if (a[action.payload] < b[action.payload]) return -1;
          if (a[action.payload] > b[action.payload]) return 1;
          return 0;
        });
        
        draft.loading = true;
        draft.chunkMetaDataArray   = sortedItems;
        draft.orderColumn = draft.orderColumns.find((x) => x.column === action.payload);
        break;
  
      case METADATA_SEARCH:
        if (action.payload === ''){
          draft.loading = true;
          draft.chunkMetaDataArray   = draft.allMetaDataArray;
          break;
        }
        
        const keyword = action.payload.toLowerCase();
        
        const searchItems = draft.allMetaDataArray.filter(
          (item) =>
            item.survey_name.toLowerCase().indexOf(keyword) > -1 ||
            item.status.toLowerCase().indexOf(keyword) > -1 ||
            item.category.toLowerCase().indexOf(keyword) > -1
        );
        draft.loading = true;
        draft.chunkMetaDataArray   = searchItems;
        draft.searchKeyword  = action.payload;
        break;
  
      case METADATA_ADD_ITEM:
        draft.loading = false;
        break;
  
      case METADATA_ADD_ITEM_SUCCESS:
        draft.loading = true;
        draft.allMetaDataArray = action.payload;
        draft.chunkMetaDataArray = action.payload;
        break;
  
      case METADATA_ADD_ITEM_ERROR:
        draft.loading = true;
        draft.error    = action.payload;
        break;
  
      case METADATA_SELECTED_ITEMS_CHANGE:
        draft.loading = true;
        draft.selectedItems = action.payload;
        break;
      
      case UPDATE_ALL_METADATA:
        draft.loading = true;
        draft.allMetaData = action.payload.allMetaData || draft.allMetaData;
        draft.chunkMetaDataArray = action.payload.array || draft.chunkMetaDataArray;
        draft.allMetaDataArray   = action.payload.array || draft.allMetaDataArray;
        draft.metaOverView       = action.payload.metaOverView || draft.metaOverView;
        break;

      default:
        return draft;
    }
  });
};
