import {
    ADD_NEW_SURVEY
} from '../constants';
import produce from 'immer';

const inState = {
  isModalOpen : false
};

export default (state = inState,action)=>{
    return produce(state,draft=>{
        switch(action.type){
            case ADD_NEW_SURVEY:
                draft.isModalOpen  = action.payload
                break
            default:
                return state
        }
    });
};