import { Row } from 'reactstrap';
import { Colxx } from "components/common/CustomBootstrap";
import { NotificationManager } from 'components/common/react-notifications';

const createErrorNotification = (message)=>{
    NotificationManager.error(
        <Row className='d-flex flex-nowrap'>
            <Colxx lg={1} xxs={1}><i className='simple-icon-close h5'></i></Colxx>
            <Colxx lg={11} xxs={11}><h4 className='text-light'>{message}</h4></Colxx>
        </Row>,
        '',
        5000,
        () => {},
        null,
        'filled'
      );
};

const createSuccessNotification = (message)=>{
    NotificationManager.primary(
        <Row className='d-flex flex-nowrap'>
            <Colxx lg={1} xxs={1}><i className='simple-icon-check h5'></i></Colxx>
            <Colxx lg={11} xxs={11}><h4 className='text-light'>{message}</h4></Colxx>
        </Row>,
        '',
        5000,
        () => {},
        null,
        'filled'
      );
};

export {
    createErrorNotification,
    createSuccessNotification
}