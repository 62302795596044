import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { GET_QUESTIONS } from '../constants';
import {
  getQuestionSuccess,
  getQuestionError
} from './actions';
import { getQuestions } from 'utils/api';

const getQuestionRequest = async (surveyUID) => {
  const qesReq = getQuestions(surveyUID);

  return await new Promise((success) => {
    setTimeout(() => {
      success(qesReq);
    }, 1000);
  })
    .then((response) => response)
    .catch((error) => error);
};

function* getQuestionsSaga({payload}) {
  try {
    const form_uid = payload;
    
    const response = yield call(getQuestionRequest,form_uid);
    if(response.status == 200){
      yield put(getQuestionSuccess(form_uid,response.data));
    }
    else{
      yield put(getQuestionError(response.status));
    }
 } catch (error) {
    yield put(getQuestionError(error));
  }
}

export function* watchGetDetail() {
  yield takeEvery(GET_QUESTIONS,getQuestionsSaga);
}

export default function* rootSaga() {
  yield all([fork(watchGetDetail)]);
}