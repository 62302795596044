import { setCurrentLanguage } from 'data/helpers/Utils';
import { CHANGE_LOCALE,GET_SETTINGS,GET_SETTINGS_SUCCESS,GET_SETTINGS_ERROR,SAVE_SETTINGS,UPDATE_RECEIVED_SETTINGS } from '../constants';


export const getSettings = (form_uid) => ({
  type: GET_SETTINGS,
  payload : form_uid
});

export const getSettingSuccess = (form_uid,data) => ({
  type: GET_SETTINGS_SUCCESS,
  survey_uid:form_uid,
  payload: data,
});

export const getSettingError = (code) => ({
  type: GET_SETTINGS_ERROR,
  payload: code
});

export const saveSettings = (form_uid,data) => ({
  type: SAVE_SETTINGS,
  form_uid:form_uid,
  payload:data
});

export const updateReceivedSettings = (form_uid,data)=>({
  type: UPDATE_RECEIVED_SETTINGS,
  survey_uid:form_uid,
  payload:data
});

export const changeLocale = (locale) => {
  setCurrentLanguage(locale);
  return {
    type: CHANGE_LOCALE,
    payload: locale,
  };
};
