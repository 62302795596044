import data from "constants/menu";
import { GET_FORM_LIST, GET_FORM_LIST_ERROR, GET_FORM_LIST_SUCCESS,UPDATE_FORM_DATA,FORM_FILTER_BY_KEYWORD,FORM_FILTER_BY_COLUMN,FORM_FILTER_BY_STATUS,FORM_FILTER_BY_CATEGORY } from "../constants";

export const getFormList = ()=>({
    type:GET_FORM_LIST,
});

export const getFormWithKeyword = (keyword) =>({
    type : FORM_FILTER_BY_KEYWORD,
    payload : keyword,
});

export const getFormWithStatus = (status) => ({
    type : FORM_FILTER_BY_STATUS,
    payload : status,
});

export const getFormByCategory = (category) =>({
    type : FORM_FILTER_BY_CATEGORY,
    payload : category,
});

export const getFormsWithOrder = (column) =>({
    type : FORM_FILTER_BY_COLUMN,
    payload : column,
});

export const getFormListSuccess = (data) => ({
    type: GET_FORM_LIST_SUCCESS,
    payload: data,
});
  
export const getFormListError = (err) => ({
    type: GET_FORM_LIST_ERROR,
    payload: err
});

export const updateFormData = (data) =>({
    type: UPDATE_FORM_DATA,
    payload: data
})