import {
    GET_RESPONSE,
    RESPONSE_SUCCESS,
    RESPONSE_ERROR
} from '../constants';

export const getResponse = (survey_uid)=>({
    type: GET_RESPONSE,
    payload : survey_uid
});

export const getResponseSuccess = (allData)=>({
    type : RESPONSE_SUCCESS,
    payload : allData
});

export const getResponseError = (err)=>({
    type : RESPONSE_ERROR,
    payload : err
});