/* MENU */
export const MENU_SET_CLASSNAMES = 'MENU_SET_CLASSNAMES';
export const MENU_CONTAINER_ADD_CLASSNAME = 'MENU_CONTAINER_ADD_CLASSNAME';
export const MENU_CLICK_MOBILE_MENU = 'MENU_CLICK_MOBILE_MENU';
export const MENU_CHANGE_DEFAULT_CLASSES = 'MENU_CHANGE_DEFAULT_CLASSES';
export const MENU_CHANGE_HAS_SUB_ITEM_STATUS =
    'MENU_CHANGE_HAS_SUB_ITEM_STATUS';

/* ALL SURVEYS META DATA */
export const GET_ALL_METADATA = 'GET_ALL_METADATA';
export const GET_METADATA_SUCCESS = 'GET_METADATA_SUCCESS';
export const GET_METADATA_ERROR = 'GET_M    ETADATA_ERROR';
export const SET_META_STATUS = 'SET_META_STATUS';
export const METADATA_WITH_FILTER = 'METADATA_WITH_FILTER';
export const METADATA_WITH_ORDER = 'METADATA_WITH_ORDER';
export const METADATA_SEARCH = 'METADATA_SEARCH';
export const METADATA_ADD_ITEM = 'METADATA_ADD_ITEM';
export const METADATA_ADD_ITEM_SUCCESS = 'METADATA_ADD_ITEM_SUCCESS';
export const METADATA_ADD_ITEM_ERROR = 'METADATA_ADD_ITEM_ERROR';
export const METADATA_SELECTED_ITEMS_CHANGE = 'METADATA_SELECTED_ITEMS_CHANGE';
export const UPDATE_ALL_METADATA = 'UPDATE_ALL_METADATA';

/** QUESTIONS */
export const GET_QUESTIONS = 'GET_QUESTIONS';
export const GET_QUESTIONS_SUCCESS = 'GET_QUESTIONS_SUCCESS';
export const GET_QUESTIONS_ERROR = 'GET_QUESTIONS_ERROR';
export const DELETE_QUESTION = 'DELETE_QUESTION';
export const SAVE_QUESTIONS = 'SAVE_QUESTIONS';
export const SAVE_OPTIONS = 'SAVE_OPTIONS';
export const SET_QUESTION_REQ = 'SET_QUESTION_REQ';
export const SET_REQ_LOADING = 'SET_REQ_LOADING';

/** SETTINGS */
export const CHANGE_LOCALE = 'CHANGE_LOCALE';
export const GET_SETTINGS = 'GET_SETTINGS';
export const GET_SETTINGS_SUCCESS = 'GET_SETTINGS_SUCCESS';
export const GET_SETTINGS_ERROR = 'GET_SETTINGS_ERROR';
export const SAVE_SETTINGS = 'SAVE_SETTINGS';
export const UPDATE_RECEIVED_SETTINGS = 'UPDATE_RECEIVED_SETTINGS';

/** ALL RESPONSE */
export const GET_RESPONSE = 'GET_RESPONSE';
export const RESPONSE_SUCCESS = 'RESPONSE_SUCCESS';
export const RESPONSE_ERROR = 'RESPONSE_ERROR';

/** COMMON UTIL CONSTANTS */
export const ADD_NEW_SURVEY = 'ADD_NEW_SURVEY';

/** USER CONSTANTS */
export const USER_VERIFY = 'USER_VERIFY';
export const USER_NOT_VERIFIED = 'USER_NOT_VERIFIED';
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_SIGNUP_SUCCESS = 'USER_SIGNUP_SUCCESS';
export const USER_PROFILE_UPDATE = 'USER_PROFILE_UPDATE';

/** SURVEY META DATA */
export const STORE_META_DATA = 'STORE_META_DATA';
export const FAILED_META_DATA = 'FAILED_META_DATA';

/**FORM */
export const GET_FORM_LIST = 'GET_FORM_LIST';
export const FORM_FILTER_BY_KEYWORD = 'FORM_FILTER_BY_KEYWORD';
export const FORM_FILTER_BY_COLUMN = 'FORM_FILTER_BY_COLUMN';
export const FORM_FILTER_BY_STATUS = 'FORM_FILTER_BY_STATUS';
export const FORM_FILTER_BY_CATEGORY = 'FORM_FILTER_BY_CATEGORY';
export const GET_FORM_LIST_SUCCESS = 'GET_FORM_LIST_SUCCESS';
export const GET_FORM_LIST_ERROR = 'GET_FORM_LIST_ERROR';
export const UPDATE_FORM_DATA = 'UPDATE_FORM_DATA';

/** FORM RESOURCES */
export const GET_FORM_RESOURCES = 'GET_FORM_RESOURCES';
export const GET_FORM_RESOURCES_SUCCESS = 'GET_FORM_RESOURCES_SUCCESS';
export const GET_FORM_RESOURCES_ERROR = 'GET_FORM_RESOURCES_ERROR';

/** FORM ANALYTICS */
export const GET_FORM_ANALYTICS = 'GET_FORM_ANALYTICS';
export const GET_FORM_ANALYTICS_SUCCESS = 'GET_FORM_ANALYTICS_SUCCESS';
export const GET_FORM_ANALYTICS_ERROR = 'GET_FORM_ANALYTICS_ERROR';