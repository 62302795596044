import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { GET_SETTINGS } from '../constants';
import {
  getSettingSuccess,
  getSettingError
} from './actions';
import { getSettings } from 'utils/api';

const getSettingRequest = async (surveyUID) => {
  const setReq = getSettings(surveyUID);

  return await new Promise((success) => {
    setTimeout(() => {
      success(setReq);
    }, 1000);
  })
    .then((response) => response)
    .catch((error) => error);
};

function* getSettingSaga({payload}) {
  try {
    const surveyUID = payload;
    
    const response = yield call(getSettingRequest,surveyUID);
    if(response.status == 200){
      yield put(getSettingSuccess(surveyUID,response.data));
    }
    else{
      yield put(getSettingError(response.status));
    }
 } catch (error) {
    yield put(getSettingError(error));
  }
}

export function* watchGetDetail() {
  yield takeEvery(GET_SETTINGS,getSettingSaga);
}

export default function* rootSaga() {
  yield all([fork(watchGetDetail)]);
}