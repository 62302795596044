import {
  GET_ALL_METADATA,
  GET_METADATA_SUCCESS ,
  GET_METADATA_ERROR ,
  SET_META_STATUS,
  METADATA_WITH_FILTER ,
  METADATA_WITH_ORDER ,
  METADATA_SEARCH ,
  METADATA_ADD_ITEM ,
  METADATA_ADD_ITEM_SUCCESS ,
  METADATA_ADD_ITEM_ERROR ,
  METADATA_SELECTED_ITEMS_CHANGE,
  UPDATE_ALL_METADATA
} from '../constants';

export const getAllMeta = () => ({
  type: GET_ALL_METADATA,
});

export const getMetaDataSuccess = (items) => ({
  type: GET_METADATA_SUCCESS,
  payload: items,
});

export const getMetaDataError = (code) => ({
  type: GET_METADATA_ERROR,
  payload: code
});

export const setMetaStatus = (status)=>({
  type: SET_META_STATUS,
  payload: status
});

export const MetaDataWithFilter = (column, value) => ({
  type: METADATA_WITH_FILTER,
  payload: { column, value },
});

export const MetaDataWithOrder = (column) => ({
  type: METADATA_WITH_ORDER,
  payload: column,
});

export const MetaDataSearch = (keyword) => ({
  type: METADATA_SEARCH,
  payload: keyword,
});

export const MetaAddItem = (item) => ({
  type: METADATA_ADD_ITEM,
  payload: item,
});

export const MetaAddItemSuccess = (items) => ({
  type: METADATA_ADD_ITEM_SUCCESS,
  payload: items,
});

export const MetaAddItemError = (error) => ({
  type: METADATA_ADD_ITEM_ERROR,
  payload: error,
});

export const MetaSelectedItemsChange = (selectedItems) => ({
  type: METADATA_SELECTED_ITEMS_CHANGE,
  payload: selectedItems,
});

// export const updateMetaArrays = (newArray)=>({
//   type: UPDATE_META_ARRAY,
//   payload: newArray,
// });

// export const updateMetaOverview = (newObject)=>({
//   type: UPDATE_META_OVER_VIEW,
//   payload: newObject,
// });

// export const updateMetaObject = (newObject)=>({
//   type : UPDATE_META_OBJECT,
//   payload : newObject
// });

export const updateAllMeta = (newAllMeta)=>({
  type : UPDATE_ALL_METADATA,
  payload : newAllMeta
});