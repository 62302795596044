import produce from 'immer';
import { FORM_FILTER_BY_COLUMN, FORM_FILTER_BY_KEYWORD, GET_FORM_LIST, GET_FORM_LIST_ERROR, GET_FORM_LIST_SUCCESS, UPDATE_FORM_DATA, FORM_FILTER_BY_STATUS, FORM_FILTER_BY_CATEGORY } from "redux/constants";
import { DateTime } from '../../utils/universe';
export const categories = ['Quiz', 'Poll', 'Market research', 'Academic research', 'Employee feedback', 'Customer feedback', 'Other feedback', 'Registration', 'Other'];
export const statusTypes = {
  active: 1,
  paused: 2,
  completed: 3
};
const inState = {
  formlist: null,
  error: null,
  filter: null,
  searchKeyword: '',
  orderColumn: null,
  loading: false,
  labels: [],
  surveys: {
    allSurveys: 0,
    recentSurvey: 0,
    ongoingSurvey: 0,
    completedSurvey: 0
  },
  orderColumn: null,
  orderColumns: [
    { column: 'created_at', label: 'Created date' },
    { column: 'category_id', label: 'category' },
    { column: 'status_id', label: 'Status' },

  ],
  categories: categories,
  new: null,
  activeSurveys: 0,
  recentSurvey: 0,
  completedSurveys: 0,
  pausedSurveys: 0,
  statusKeys: {},
  selectedItems: [],
  allMetaDataArray: []
};

function Moc(val, prev) {
  var mo = {
    recentSurveys: prev,
  };

  for (var obj in val) {
    if (obj.created_at && DateTime.isWeek(obj.created_at)) {
      mo.recentSurveys += 1;
    }
  }
  return mo.recentSurveys;
}

function Mc(aM) {
  var recentSurveyCount = 0;

  for (var i in aM) {
    recentSurveyCount = Moc(aM[i], recentSurveyCount);
  }
  return recentSurveyCount;
}

function calculateSurveysCount(formlist) {
  let activeCount = 0;
  let completedCount = 0;
  let pausedCount = 0;

  for (const item of formlist) {
    if (item.status_id === statusTypes.active) {
      activeCount++;
    } else if (item.status_id === statusTypes.completed) {
      completedCount++;
    } else if (item.status_id === statusTypes.paused) {
      pausedCount++;
    }
  }

  const recentSurveyCount = Mc(formlist);

  return { activeCount, completedCount, pausedCount, recentSurveyCount };
}

export default (state = inState, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case GET_FORM_LIST:
        draft.loading = true;
        break;

      case GET_FORM_LIST_SUCCESS:
        draft.loading = false;
        draft.formlist = action.payload.formList;
        draft.allMetaDataArray = action.payload.formList;
        draft.allMetaDataArray.forEach((item) => {
          item.hide = false;
        });
        draft.surveys.allSurveys = draft.formlist.length;

        const surveyCount = calculateSurveysCount(draft.formlist);

        draft.recentSurvey = surveyCount.recentSurvey;
        draft.activeSurveys = surveyCount.activeCount;
        draft.completedSurveys = surveyCount.completedCount;
        draft.pausedSurveys = surveyCount.pausedCount;
        break;

      case GET_FORM_LIST_ERROR:
        draft.loading = false;
        draft.error = action.payload.message;
        break;

      case FORM_FILTER_BY_COLUMN:
        if (action.payload === '') {
          draft.loading = true;
          draft.orderColumn = null;
          break;
        }

        var lower = action.payload.toLowerCase();
        var sortedItems = draft.formlist.sort((a, b) => {
          var column = draft.orderColumns.find((i) => i.label === draft.orderColumn);
          if (action.payload === 'category') {
            if (a.category_id < b.category_id) return -1;
            if (a.category_id > b.category_id) return 1;
            return 0;
          }
          if (action.payload === 'status') {
            if (a.status_id < b.status_id) return -1;
            if (a.status_id > b.status_id) return 1;
            return 0;
          }
          if (action.payload === 'created_at') {
            if (a.created_at < b.created_at) return 1;
            if (a.created_at > b.created_at) return -1;
            return 0;
          }
        });

        draft.loading = false;
        draft.formlist = sortedItems;
        draft.orderColumn = draft.orderColumns.find((x) => x.label.toLowerCase() === lower);
        break;

      case FORM_FILTER_BY_KEYWORD:
        if (action.payload === '') {
          draft.loading = false;
          draft.formlist = draft.allMetaDataArray;
          draft.searchKeyword = '';
          break;
        }

        const keyword = action.payload.trim().toLowerCase();

        draft.formlist = draft.allMetaDataArray.map(item => {
          const isMatch = item.title.toLowerCase().includes(keyword);
          return {
            ...item,
            hide: !isMatch,
          };
        });

        draft.loading = false;
        draft.searchKeyword = action.payload;
        break;

      case FORM_FILTER_BY_STATUS:
        if (action.payload === '') {
          draft.loading = false;
          draft.formlist = draft.allMetaDataArray;
          break;
        }

        const status_type = action.payload.toLowerCase();

        if (statusTypes.hasOwnProperty(status_type)) {
          draft.formlist = draft.allMetaDataArray;
          var status_items = draft.formlist.filter((item) =>
            item.status_id === statusTypes[status_type]
          );
        }

        draft.loading = false;
        draft.formlist = status_items;
        break;

      case FORM_FILTER_BY_CATEGORY:
        if (action.payload === '') {
          draft.loading = false;
          draft.formlist = draft.allMetaDataArray;
          break;
        }

        const category = action.payload;
        console.log(category);


        draft.formlist = draft.allMetaDataArray;
        var category_items = draft.formlist.filter((item) => {
          return item.category_id === category;
        });

        draft.loading = false;
        draft.formlist = category_items;
        break;


      case UPDATE_FORM_DATA:
        draft.formlist = action.payload;
        draft.allMetaDataArray = action.payload;
        draft.surveys.allSurveys = draft.formlist.length;

        const surveyUpdateCount = calculateSurveysCount(draft.formlist);

        draft.recentSurvey = surveyUpdateCount.recentSurvey;
        draft.activeSurveys = surveyUpdateCount.activeCount;
        draft.completedSurveys = surveyUpdateCount.completedCount;
        draft.pausedSurveys = surveyUpdateCount.pausedCount;
        break;

      default:
        return state
    }
  });
};