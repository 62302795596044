import {
    GET_RESPONSE,
    RESPONSE_SUCCESS,
    RESPONSE_ERROR
} from '../constants';
import produce from 'immer';
import ResponseUtils from 'utils/survey/responses';

const INIT_STATE = {
    allResponseArray:[],
    responseLoading: false,
};

export default (state = INIT_STATE, action) => {
    return produce(state,draft=>{
        switch(action.type){
            case GET_RESPONSE:
                draft.responseLoading = true;
                break;
            
            case RESPONSE_SUCCESS:
                draft.allResponseArray = action.payload;
                draft.responseLoading        = false;
                
                // if(draft.resEvents['responses'] > 0){
                //     draft.allResponseArray = ResponseUtils.constructResponseArray(action.payload.responses);
                // }
                break;

             case RESPONSE_ERROR:
                draft.responseLoading  = false;
                break;

            default:
                return draft;
        }
    });
};