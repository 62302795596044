import { adminRoot } from './defaultValues';
import { PARENT_DOMAIN } from 'env';

const data = [
  {
    id: 'menu.dashboard',
    icon: 'simple-icon-grid',
    label:  'menu.dashboard',
    to: `${adminRoot}dashboard`,
  },
  {
    id: 'menu.forms',
    icon: 'iconsminds-bucket',
    label: 'menu.forms',
    to: `${adminRoot}forms`,
  },
  {
    id: 'menu.supports',
    icon: 'iconsminds-support',
    label: 'menu.supports',
    to: `${PARENT_DOMAIN}/support`,
    newWindow: true,
  },
  {
    id: 'menu.tc',
    icon: 'simple-icon-doc',
    label: 'menu.tc',
    to: `${PARENT_DOMAIN}/terms-and-conditions/`,
    newWindow: true,
  },
  {
    id: 'menu.pp',
    icon: 'iconsminds-security-settings',
    label: 'menu.pp',
    to: `${PARENT_DOMAIN}/privacy-policy/`,
    newWindow: true,
  },
];
export default data;
