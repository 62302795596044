import {useNavigate,useParams  } from 'react-router-dom';
import { createSuccessNotification } from 'utils/ui/notification';

export const withRouter = (Component) =>{
    const Wrapper = (props) =>{
        const history = useNavigate();
        return <Component history={history} params={useParams()} {...props}/>
    } 
    return Wrapper;
}

function randomString(length) {
  var result           = '';
  var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

  for ( var i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
}

class DateTime{
    static formatUTC(utc){
        utc = new Date(utc*1000);
        var t = utc.getDate()+'.'+(utc.getMonth()+1)+'.'+utc.getFullYear();
        return t;
    }

    static isWeek(dt){
      var diffTime = (new Date().getTime() - new Date(dt*1000).getTime())/1000;

      if(diffTime < 604800){ // 1 week in seconds
        return true;
      }
      return false;
    }

    static FormatSeconds(seconds){
      var sT;
      var s=0,m = 0,h=0;
      
      h = Math.floor(seconds / 3600);
      seconds %= 3600;
      m = Math.floor(seconds / 60);
      s = (seconds % 60).toFixed(2);
      switch(true){
          case h != 0:
              sT = h+' h '+m+' m '+s+' s';
              break;
              
          case m != 0:
              sT = m+' m '+s+' s';
              break;
          
          default:
              sT = s+' s';
              break;
      }
      return sT;
    }
  }

  class CommonFunctions{
    static  randomString(length) {
      var result           = '';
      var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      var charactersLength = characters.length;
      for ( var i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
     }
     return result
    }
    
    static Redirect({to}){
      window.location.href = to;
      return <div/>;
    }

    static RedirectWithSurveyId({to}){
      const {survey_uid} = useParams();
      window.location.href = survey_uid ? to+survey_uid : to;
      return <div/>;
    }
  }

  function copyThis(text,copyMsg){
    navigator.clipboard.writeText(text);
    createSuccessNotification(copyMsg);
  }

  function updateObject(obj1, obj2){
    var newObject = Object.assign({}, obj1);
    Object.keys(obj2).forEach(k => {
      if(k in newObject) {
        newObject[k] = obj2[k];
      }
    });
    return newObject;
  }

  function objIndex(arr,key,val){
    return arr.findIndex((item)=>{
      return item[key] == val;
    });
  }
  
  function objDiff(o1,o2){
    var diff = {};
    for(var i in o2){
        if(i in o1){
            if(typeof o2[i] == 'object'){
                   var temp = objDiff(o1[i],o2[i]);
                   if(Object.keys(temp).length > 0){
                    diff[i] = temp;   
                   }
             }
             else{
                 if(o1[i] != o2[i]){
                     diff[i] = o2[i];
                 }
              }
        }
        else{
            diff[i] = o2[i];
        }
    }
    return diff;
}

function returnHttpRegex(){
  return '[Hh][Tt][Tt][Pp][Ss]?:\/\/(?:(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)(?:\.(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)*(?:\.(?:[a-zA-Z\u00a1-\uffff]{2,}))(?::\d{2,5})?(?:\/[^\s]*)?';
}

  export {
    DateTime,
    CommonFunctions,
    copyThis,
    updateObject,
    randomString,
    objIndex,
    objDiff,
    returnHttpRegex
  }