import { combineReducers } from 'redux';
import settings from './settings/reducers';
import menu from './menu/reducer';
import  UtilStates  from './utils/reducers';
import User from './user/reducers';
import MetaData from './metadata/reducers';
import FormQuestions from './questions/reducers';
import FormResponse from './response/reducers';
import Form from './form/reducers';
import FormResource from './enums/reducers';
import FormAnalytics from './analytics/reducers';

const reducers = combineReducers({
  menu,
  UtilStates,
  User,
  Form,
  MetaData,
  SurveyQuestions: FormQuestions,
  settings,
  SurveyResponse: FormResponse,
  FormResource,
  FormAnalytics
});

export default reducers;