import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { GET_FORM_LIST } from 'redux/constants';
import { getFormList } from 'utils/api';
import { getFormListError, getFormListSuccess } from './actions';

const GetFormListSaga = async () => {
    const resData = await getFormList();
  
    return await new Promise((success) => {
      setTimeout(() => {
        success(resData);
      }, 1000);
    })
      .then((response) => response)
      .catch((error) => error);
  };
  
  function* getFormListGenerator() {
    try {
      const response = yield call(GetFormListSaga);
  
      if(response.status == 200){
        yield put(getFormListSuccess(response.data));
      }
      else{
        yield put(getFormListError(response));
      }
    } catch (error) {
      console.log(error);
      window.location.href = "/login";
    }
  }
  
  export function* watchGetList() {
    yield takeEvery(GET_FORM_LIST,getFormListGenerator);
  }
  
  export default function* rootSaga() {
    yield all([fork(watchGetList)]);
  }