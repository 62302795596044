import produce from 'immer';
import { GET_FORM_RESOURCES, GET_FORM_RESOURCES_ERROR, GET_FORM_RESOURCES_SUCCESS} from "redux/constants";


const inState = {
 resourceLoading:null,
 FormTemplateList :[],
 FormCategoryList :[],
 FormActionTypeList :[],
 FormEventTypeList :[],
 FormFontFamilyList :[],
 FormStatusTypeList:[],
 error:null
};

  export default (state = inState,action)=>{
    return produce(state,draft=>{
        switch(action.type){
            case GET_FORM_RESOURCES:
                draft.resourceLoading = true;
                break;
            
            case GET_FORM_RESOURCES_SUCCESS:
                draft.resourceLoading = false;
                draft.FormTemplateList = action.payload.FormTemplateList;
                draft.FormCategoryList = action.payload.FormCategoryList;
                draft.FormActionTypeList = action.payload.FormActionTypeList;
                draft.FormEventTypeList = action.payload.FormEventTypeList;
                draft.FormFontFamilyList = action.payload.FormFontFamilyList;
                draft.FormStatusTypeList = action.payload.FormStatusTypeList;
                break;
            
            case GET_FORM_RESOURCES_ERROR:
                draft.resourceLoading = false;
                draft.error = action.payload.message
                break;
                
            default:
                return state
        }
    });
};