import { all } from 'redux-saga/effects';
import MetaDataSagas from './metadata/saga';
import SurveyQuestionSaga from './questions/saga';
import SurveySettings from './settings/saga';
import SurveyResponseSaga from './response/saga';
import FormSaga from './form/saga';
import FormResourceSaga from './enums/saga';
import FormAnalyticsSaga from './analytics/saga';

export default function* rootSaga() {
  yield all([
    MetaDataSagas(),
    SurveyQuestionSaga(),
    SurveySettings(),
    SurveyResponseSaga(),
    FormSaga(),
    FormResourceSaga(),
    FormAnalyticsSaga()
  ]);
}
