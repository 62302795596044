import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { GET_FORM_RESOURCES } from 'redux/constants';
import {  getFormResources } from 'utils/api';
import { getFormResourcesSuccess,getFormResourcesError } from './actions';

const GetFormResourceSaga = async () => {
    const resData = await getFormResources();
  
    return await new Promise((success) => {
      setTimeout(() => {
        success(resData);
      }, 1000);
    })
      .then((response) => response)
      .catch((error) => error);
  };
  
  function* getFormResourceGenerator() {
    try {
      const response = yield call(GetFormResourceSaga);
  
      if(response.status == 200){
        yield put(getFormResourcesSuccess(response.data));
      }
      else{
        yield put(getFormResourcesError(response));
      }
    } catch (error) {
      console.log(error);
    }
  }
  
  export function* watchGetList() {
    yield takeEvery(GET_FORM_RESOURCES,getFormResourceGenerator);
  }
  
  export default function* rootSaga() {
    yield all([fork(watchGetList)]);
  }