const PARENT_DOMAIN = process.env.REACT_APP_SITE;
const ACCOUNT_LINK  = '/account';
const FEEDBACK_LINK = process.env.REACT_APP_SITE+'/feedback';
const PRE_LINK = process.env.REACT_APP_SITE+'/f/';
const APP_NAME = process.env.REACT_APP_NAME;

//Policies
const TERMS_LINK = process.env.REACT_APP_SITE+'/terms-and-conditions';
const PRIVACY_LINK = process.env.REACT_APP_SITE+'/privacy-policy';
const COOKIE_LINK = process.env.REACT_APP_SITE+'/cookie-policy';

function redirectURL(suffix_link){
    return (PARENT_DOMAIN+`/login?redirect=${PARENT_DOMAIN}`+suffix_link);
}

export {
    APP_NAME,
    PARENT_DOMAIN,
    PRE_LINK,
    ACCOUNT_LINK,
    FEEDBACK_LINK,
    TERMS_LINK,
    PRIVACY_LINK,
    COOKIE_LINK,
    redirectURL
};