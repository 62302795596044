import { GET_FORM_RESOURCES,GET_FORM_RESOURCES_SUCCESS,GET_FORM_RESOURCES_ERROR} from "redux/constants";

export const getFormResources = ()=>({
    type:GET_FORM_RESOURCES
});

export const getFormResourcesSuccess = (data)=>({
    type:GET_FORM_RESOURCES_SUCCESS,
    payload:data
});

export const getFormResourcesError = (err)=>({
    type:GET_FORM_RESOURCES_ERROR,
    payload:err
});