import {
  GET_QUESTIONS ,
  GET_QUESTIONS_SUCCESS ,
  GET_QUESTIONS_ERROR ,
  SAVE_QUESTIONS,
  SET_QUESTION_REQ,
  SET_REQ_LOADING,
  SAVE_OPTIONS
} from '../constants';
import produce from 'immer';

export const answerTypes = [
  { label: 'Text', value: '1', id: 1 },
  { label: 'Number', value: '2', id: 2 },
  { label: 'Date', value: '3', id: 3 },
  { label: 'Email', value: '4', id: 4 },
  { label: 'Phone', value: '5', id: 5 },
  { label: 'URL', value: '6', id: 6 },
  { label: 'Comment', value: '7', id: 7 },
  { label: 'Checkbox', value: '8', id: 8 },
  { label: 'Radiobutton', value: '9', id: 9 },
  { label: 'Dropdown', value: '10', id: 10 },
  { label: 'Rating', value: '11', id: 11 },
];

const INIT_STATE = {
  questionStatus:null,
  receivedQuestions:{},
  receivedOptions:{},
  questions: {},
  questionOrder:{},
  options:{},
  questionLoading: null,
  error:null,
  questionReq:{},
  reqLoading:false
};

export default (state = INIT_STATE, action) => {
  return produce(state,draft=>{
    switch (action.type) {
      case GET_QUESTIONS:
        draft.questionLoading = true;
        break;
  
      case GET_QUESTIONS_SUCCESS:
        try {
          draft.questionLoading = false;
          let survey_uid = action.survey_uid;
          draft.receivedQuestions[survey_uid]    = action.payload.questions;
          draft.receivedOptions[survey_uid]    = action.payload.options;  
 
          draft.questionStatus  = 200;
          draft.questions[survey_uid] = {...action.payload.questions}
          draft.options[survey_uid] = {...action.payload.options}
          // for(var i in action.payload.questions){
          //   draft.questions[action.survey_uid] = Object.assign(draft.questions[survey_uid],action.payload.questions[i])
          // }
          
          draft.questionOrder[survey_uid] = action.payload.question_order;
        } catch (error) {
           console.log(error);
           throw error;
        }
        break;
  
      case GET_QUESTIONS_ERROR:
        draft.questionLoading = false;
        draft.questionStatus = action.payload;
        break;
  
      case SAVE_QUESTIONS:
        if(action.payload.questions){
          draft.questions[action.form_uid] = action.payload.questions;
        }
        if(action.payload.question_order){
          draft.questionOrder[action.form_uid] = action.payload.question_order;
        }
        break;
      
      case SAVE_OPTIONS:
        if(action.payload.options){
          draft.options[action.form_uid] = action.payload.options;
        }
        break;

      case SET_QUESTION_REQ:
         if(!(action.form_uid in draft.questionReq)){
          draft.questionReq[action.form_uid] = {
            add : {},
            update : [],
            question_order  : []
          }
         }
         draft.questionReq[action.form_uid] = action.payload
         break;
      
      case SET_REQ_LOADING:
         draft.reqLoading = action.payload;
         break;
         
      default:
        draft;
    }
  });
};