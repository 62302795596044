import { getCurrentLanguage } from 'data/helpers/Utils';
import { CHANGE_LOCALE,GET_SETTINGS,GET_SETTINGS_SUCCESS,GET_SETTINGS_ERROR,SAVE_SETTINGS,UPDATE_RECEIVED_SETTINGS } from '../constants';
import produce from 'immer';


export const opKeyAfterCompletion = (currKey) =>{
  return (currKey == 'message') ? 'url' : 'message';
};

//TODO: Remove this
export const FontMenu = {
 montserrat : "Montserrat" ,
 worksans   : "Work Sans" ,
 poppins    : "Poppins" ,
 sourcesanspro: "Source Sans Pro" ,
 sansserif : "Sans-serif" 
};

const INIT_STATE = {
  locale: getCurrentLanguage(),
  settingStatus:null,
  receivedObj:{},
  settingsObj:{},
  settingsLoading:false
};

export default (state = INIT_STATE, action) => {
  return produce(state,draft=>{
    switch (action.type) {
      case CHANGE_LOCALE:
        draft.locale = action.payload;
        break;
      
      case GET_SETTINGS:
        draft.settingsLoading = true;
        break;
      
      case GET_SETTINGS_SUCCESS:
        draft.settingsLoading = false;
        draft.settingStatus  = 200;
        draft.receivedObj[action.survey_uid] = action.payload;
        draft.settingsObj[action.survey_uid] = action.payload;
        break;
      
      case GET_SETTINGS_ERROR:
        draft.settingsLoading = false;
        draft.settingStatus = action.payload;
        break;
      
      case SAVE_SETTINGS:
        draft.settingsObj[action.form_uid] = action.payload;
        break;
      
      case UPDATE_RECEIVED_SETTINGS:
          draft.receivedObj[action.survey_uid] = action.payload;
          break;     

      default:
        draft;
    }
  });
};
