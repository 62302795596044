import {
   GET_FORM_ANALYTICS,
   GET_FORM_ANALYTICS_SUCCESS,
   GET_FORM_ANALYTICS_ERROR
} from '../constants';

export const getAnalytics = (form_uid)=>({
    type: GET_FORM_ANALYTICS,
    payload : form_uid
});

export const getAnalyticsSuccess = (form_uid,data)=>({
    type : GET_FORM_ANALYTICS_SUCCESS,
    form_uid: form_uid,
    payload : data
});

export const getAnalyticsError = (err)=>({
    type : GET_FORM_ANALYTICS_ERROR,
    payload : err
});