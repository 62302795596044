import {
  defaultMenuType,
  subHiddenBreakpoint,
  menuHiddenBreakpoint,
} from 'constants/defaultValues';

import {
  MENU_SET_CLASSNAMES,
  MENU_CONTAINER_ADD_CLASSNAME,
  MENU_CLICK_MOBILE_MENU,
  MENU_CHANGE_DEFAULT_CLASSES,
  MENU_CHANGE_HAS_SUB_ITEM_STATUS,
} from '../constants';
import produce from "immer";

const INIT_STATE = {
  containerClassnames: defaultMenuType,
  subHiddenBreakpoint,
  menuHiddenBreakpoint,
  menuClickCount: 0,
  selectedMenuHasSubItems: false,
};

const menu = (state = INIT_STATE, action) => {

 var a =  produce(state,draft=>{
    switch (action.type) {
      case MENU_CHANGE_HAS_SUB_ITEM_STATUS:
        draft.selectedMenuHasSubItems = action.payload;
        break
  
      case MENU_SET_CLASSNAMES:
        draft.containerClassnames = action.payload.containerClassnames;
        draft.menuClickCount = action.payload.menuClickCount;
        break
  
  
      case MENU_CLICK_MOBILE_MENU:
        draft.containerClassnames = action.payload.containerClassnames,
        draft.menuClickCount = action.payload.menuClickCount;
        break;
  
      case MENU_CONTAINER_ADD_CLASSNAME:
        draft.containerClassnames = action.payload;
        break;
  
      case MENU_CHANGE_DEFAULT_CLASSES:
        draft.containerClassnames = action.payload;
        break;
  
      default:
        return state
    }    
  });
return a;
};
export default menu;
