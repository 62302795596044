import {
    GET_RESPONSE,
} from '../constants';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { getResponseSuccess,getResponseError } from './actions';
import { getSurveyResponse } from 'utils/api';

const resReq = async(form_uid)=>{
    const resData = await getSurveyResponse(form_uid);

    return await new Promise((success) => {
      setTimeout(() => {
        success(resData);
      }, 1000);
    })
      .then((response) => response)
      .catch((error) => error);
};

function* getResponseData({payload}) {
    try {
      const form_uid = payload;
      const response = yield call(resReq,form_uid);
  
      if(response.status == 200){
        yield put(getResponseSuccess(response.data));
      }
      else{
        yield put(getResponseError(response));
      }
    } catch (error) {
      console.log(error);
    }
  }

  export function* watchGetList() {
    yield takeEvery(GET_RESPONSE,getResponseData);
  }
  
  export default function* rootSaga() {
    yield all([fork(watchGetList)]);
  }