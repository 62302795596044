import {
   GET_QUESTIONS ,
   GET_QUESTIONS_SUCCESS ,
   GET_QUESTIONS_ERROR ,
   DELETE_QUESTION ,
   SAVE_QUESTIONS,
   SET_QUESTION_REQ,
   SET_REQ_LOADING,
   SAVE_OPTIONS
} from '../constants';

export const getQuestions = (survey_uid) => ({
  type: GET_QUESTIONS,
  payload:survey_uid
});

export const getQuestionSuccess = (survey_uid,items) => ({
  type: GET_QUESTIONS_SUCCESS,
  survey_uid:survey_uid,
  payload: items,
});

export const getQuestionError = (error) => ({
  type: GET_QUESTIONS_ERROR,
  payload: error,
});

export const deleteQuestion = (qUID) => ({
  type: DELETE_QUESTION,
  payload: qUID,
});

export const saveQuestions = (form_uid,data) => ({
  type: SAVE_QUESTIONS,
  form_uid: form_uid,
  payload: data,
});

export const saveOptions = (form_uid,data) => ({
  type: SAVE_OPTIONS,
  form_uid: form_uid,
  payload: data,
});

export const setReq = (form_uid,data)=> ({
  type : SET_QUESTION_REQ,
  form_uid:form_uid,
  payload : data
});

export const setReqLoading = (loading)=> ({
  type : SET_REQ_LOADING,
  payload : loading
});