import axios from 'axios';

// Send cookie with request by default
axios.defaults.withCredentials = true;
axios.defaults.headers =  {
    'Content-Type': "application/json",
};
axios.defaults.baseURL = process.env.REACT_APP_API_URL

axios.interceptors.response.use(function (response) {
    if (response.data) {
        return response;
    }
    //If there is no Data, then it is not a  Proper Server Reponse
    return Promise.reject({"message": "Invalid Server Response"});
  }, function (error) {
    if(error.response.status === 401){
        //TODO : Store last accessed page & redirect after login
        window.location = "/login";
    }
    return Promise.reject(error);
  });

var req;

async function getUser(){
     return axios.get("/userdata").catch((e)=>{
        return e.response;
    });
}

async function login(data){
    return axios.post("/login",data).catch((e)=>{
       return e.response;
   });
}

async function signup(data){
    return axios.post("/signup",data).catch((e)=>{
       return e.response;
   });
}

async function verifyActivateKey(activateKey){
    return axios.post(`/verify/${activateKey}`).catch((e)=>{
       return e.response;
   });
}

async function forgotPassword(data){
    return axios.post("/forgotpassword",data).catch((e)=>{
       return e.response;
   });
}

async function verifyLink(resetKey){
    return axios.post(`/forgotpassword/${resetKey}`).catch((e)=>{
        return e.response;
    })
}

async function updatePassword(resetkey,data){
    return axios.post(`/forgotpassword/${resetkey}/updatepassword`,data).catch((e)=>{
       return e.response;
   });
}

async function updateProfile(data) {
    return axios.put(`/updateProfile`, data).catch((e) => {
        return e.response;
    });
}

async function getSurveyMetaData(){
    req = {};
    return axios.post("/metadata",req).catch((e)=>{
            return e.response;
    });
}

async function getFormList(){
        req = {};
        return axios.get("/getformlist",req).catch((e)=>{
                return e.response;
        });
}

async function createNewSurvey(req){
   return axios.post("/createform",req).catch((e)=>{
            console.log(e.response);
            return e.response;
   });
}

async function updateFormMetaData(data,form_uid){
    return axios.post(`/${form_uid}/metadata`,data).catch((e)=>{
            return e.response;
   });
}


async function getQuestions(formID){
   return axios.get(`/${formID}/questions`).catch((e)=>{
            return e.response;
   });
}

async function addQuestions(form_uid,data){
    return axios.post(`/${form_uid}/questions`,{
        questions: data
    }).catch((e)=>{
             return e.response;
    });
}

async function updateQuestions(form_uid,data){
    return axios.put(`/${form_uid}/questions`,{
        questions: data
    }).catch((e)=>{
             return e.response;
    });
}

async function updateBulkQuestions(form_uid,data){
    return axios.post(`/${form_uid}/questions/bulk`,data).catch((e)=>{
             return e.response;
    });
}

 async function updateQuestionOrder(form_uid,data){
    return axios.post(`/${form_uid}/questionorder`,{
        question_order : data
    }).catch((e)=>{
             return e.response;
    });
}

async function addOptions(form_uid,q_uid,data){
    return axios.post(`/${form_uid}/${q_uid}/options`,{
        options : data
    }).catch((e)=>{
             return e.response;
    });
}

async function updateOptions(form_uid,q_uid,data){
    return axios.put(`/${form_uid}/${q_uid}/options`,{
        options : data
    }).catch((e)=>{
             return e.response;
    });
}

async function getSettings(formID){
    return axios.get(`/${formID}/settings`).catch((e)=>{
            return e.response;
   });
}

async function getSurveyResponse(form_uid){
        return axios.get(`/${form_uid}/responses`).catch((e)=>{
                return e.response;
       });
}

async function updateSettings(form_uid,data){
    return axios.put(`/${form_uid}/settings`,data).catch((e)=>{
            return e.response;
   });
}

async function deleteSurvey(form_uid){
    return axios.delete(`/${form_uid}/delete`).catch((e)=>{
            return e.response;
   });
}

async function getSurvey(form_url){
    return axios.get(`/getform/${form_url}`).catch((e)=>{
            return e.response;
   });
}

async function addResponse(form_url,data){
    return axios.post(`/${form_url}/response`,data).catch((e)=>{
            return e.response;
   });
}

async function logOut(){
    return axios.post('/logout').catch((e)=>{
        return e.response;
});
}

async function getFormResources(){
    return axios.get("/formresources").catch((e)=>{
            return e.response;
    });
}

async function getFormAnalytics(form_uid){
    return axios.get(`/${form_uid}/analytics/basic`).catch((e)=>{
            return e.response;
    });
}

export {
    getUser,
    login,
    signup,
    verifyActivateKey,
    forgotPassword,
    verifyLink,
    updatePassword,
    createNewSurvey,
    getFormList,
    getSurveyMetaData,
    getSettings,
    getQuestions,
    addQuestions,
    updateQuestions,
    updateBulkQuestions,
    updateQuestionOrder,
    addOptions,
    updateOptions,
    getSurveyResponse,
    updateFormMetaData,
    updateSettings,
    deleteSurvey,
    getSurvey,
    addResponse,
    logOut,
    getFormResources,
    getFormAnalytics,
    updateProfile
};