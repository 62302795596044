import {
    GET_FORM_ANALYTICS,
    GET_FORM_ANALYTICS_SUCCESS,
    GET_FORM_ANALYTICS_ERROR
} from '../constants';
import produce from 'immer';

const INIT_STATE = {
    form_analytics : {},
    analyticsLoading: false,
};

export default (state = INIT_STATE, action) => {
    return produce(state,draft=>{
        switch(action.type){
            case GET_FORM_ANALYTICS:
                draft.analyticsLoading = true;
                break;
            
            case GET_FORM_ANALYTICS_SUCCESS:
                draft.form_analytics[action.form_uid] = action.payload;
                draft.analyticsLoading = false;
                break;

             case GET_FORM_ANALYTICS_ERROR:
                draft.analyticsLoading = false;
                break;

            default:
                return draft;
        }
    });
};