import {
    USER_VERIFY,
    USER_NOT_VERIFIED,
    USER_LOGIN_SUCCESS,
    USER_SIGNUP_SUCCESS,
    USER_PROFILE_UPDATE
} from '../constants';
import produce from 'immer';

const inState = {
    userLogged: null,
    firstName: null,
    lastName: null,
    gender: null,
    email: null,
    profile_pic: null,
};

//todo : get data from asyncStorage

export default (state = inState, action) => {
    return produce(state, draft => {
        switch (action.type) {
            case USER_LOGIN_SUCCESS || USER_SIGNUP_SUCCESS:
                draft.userLogged = true;
                break;

            case USER_PROFILE_UPDATE:
                draft.firstName = action.payload.firstName
                draft.lastName = action.payload.lastName
                draft.gender = action.payload.gender
                draft.email = action.payload.email
                break;

            case USER_VERIFY:
                draft.userLogged = true;
                draft.firstName = action.payload.firstName
                draft.lastName = action.payload.lastName
                draft.gender = action.payload.gender
                draft.email = action.payload.email
                draft.profile_pic = action.payload.profile_pic
                break

            case USER_NOT_VERIFIED:
                draft.userLogged = false;
                break

            default:
                return state
        }
    });
};