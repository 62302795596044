import { all, call, fork, put, takeEvery } from 'redux-saga/effects';


import {
  GET_ALL_METADATA
} from '../constants';

import {
  getMetaDataSuccess,
  getMetaDataError
} from './actions';
import { getSurveyMetaData } from 'utils/api';


const MetaReq = async () => {
  const reqData = await getSurveyMetaData();

  return await new Promise((success) => {
    setTimeout(() => {
      success(reqData);
    }, 1000);
  })
    .then((response) => response)
    .catch((error) => error);
};

function* getAllMeta() {
  try {
    const response = yield call(MetaReq);

    if(response.status == 200){
      yield put(getMetaDataSuccess(response.data.data));
    }
    else{
      yield put(getMetaDataError(response.status));
    }
  } catch (error) {
    console.log(error);
  }
}

export function* watchGetList() {
  yield takeEvery(GET_ALL_METADATA,getAllMeta);
}

export default function* rootSaga() {
  yield all([fork(watchGetList)]);
}