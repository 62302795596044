class ResponseUtils{

    static constructResponseArray(responses){
        var allResponseArray = [];

        for(var i in responses){
            var answerTemp = {...responses[i]['answer']};
            answerTemp['total_time_spent'] = this.TotalTimeSpent(answerTemp,responses[i]['started_time']);
            allResponseArray.push(answerTemp);
        }
        return allResponseArray;
    }

    static TotalTimeSpent(answers,startedTime){
        var avg_ts = 0; // in seconds
        var count = 0;
        var previousTime;
        
        for(var i in answers){
            // CheckBox question can have multiple answers & time [ dataType : Object ] , we'll use first time `0` only
            var time = (answers[i]['time'] == undefined) ? answers[i][Object.keys(answers[i])[0]]['time'] : answers[i]['time'];

            switch(count){
                case 0:
                    avg_ts += time - startedTime;
                    break;

                default:
                    avg_ts += time - previousTime;
                    break;
            }
            previousTime = time;
            count++;
        }
        return avg_ts;
    }
    
    static AvgTimeSpent(data) {
        let totalTime = 0;
        let validEntries = 0;
      
        data.forEach(entry => {
          const startedAt = new Date(entry.startedAt);
          const createdAt = new Date(entry.createdAt);
      
          // Check if both timestamps are valid
          if (!isNaN(startedAt) && !isNaN(createdAt)) {
            const timeDifference = createdAt - startedAt;
      
            // Check if the time difference is valid (greater than 0)
            if (timeDifference > 0) {
              totalTime += timeDifference;
              validEntries++;
            }
          }
        });
      
        // Calculate the average time spent
        if (validEntries > 0) {
          const averageTime = totalTime / validEntries;
          return averageTime/1000;
        } else {
          return 0; // No valid entries or time spent is 0
        }
    }

    static completionRate(views,responses){
        var percentage;
        if(responses == 0){
            return '0%';
        }

        percentage = (responses / views) * 100;
        if(percentage > 100){
            percentage = '100%';
        }
        else{
            percentage = percentage.toFixed(2)+'%';
        }
        return percentage;
    }

    static buildResponseArray(questionIndex,questions,type,responseArray=false){
        var bArray = [];
        var currentQuestion;

        switch(type){
            case 'TABLE':
                questionIndex.forEach(i => {
                    currentQuestion = questions[i];
                    bArray.push({
                      Header: currentQuestion['title'],
                      accessor: i,
                      cellClass: 'list-item-heading w-20',
                      Cell: (props)=>this.valueCell(props,i),
                      
                     });
                   });
                  break;
            
            case 'CSV':
                const questionTexts = questionIndex.map(q => questions[q].question);
                bArray = [questionTexts];
                
                responseArray.forEach(answerObj => {
                  const row = questionIndex.map((q) =>{ 
                    var ans = answerObj.answers[q] !== undefined ? answerObj.answers[q]["value"] : "-";
                    return ans;
                  });
                  bArray.push(row);
                });
                break;

            default:
                return;
        }
        return bArray;
    }

    static valueCell(props,accessor){
        if(props.row === undefined){
            return <div className='text-muted'>-</div>;
        }

        var rowValue = props.row?.original?.answers;
        var answer   = rowValue[accessor];

        if(answer === undefined){
          return <div className='text-muted'>-</div>;
        }

        switch(true){          
          case answer?.value === undefined && Object.keys(answer["value"]).length > 0 : // CheckBox question
             var cu_value = '';
             for(var j in answer){
                cu_value += (cu_value == '') ? answer[j]['value'] : ', '+answer[j]['value'];
             }
             return <>{cu_value}</>;
  
          default:
            if(answer['value'] === ""){
                return <div className='text-muted'>-</div>;
            }
            return <>{answer['value']}</>;
        }
      }
}

export default ResponseUtils;